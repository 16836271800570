import { call, put, select } from 'redux-saga/effects';
import {
  createDistrictFailed,
  createDistrictSuccess,
  createLocationFailed,
  createLocationSuccess,
  createRegionFailed,
  createRegionSuccess,
  deleteLocationFailed,
  deleteLocationSuccess,
  fetchContactsFail,
  fetchContactsSuccess,
  fetchDistricts,
  fetchDistrictsSuccess,
  fetchLocationAssignmentCountFail,
  fetchLocationAssignmentCountSuccess,
  fetchLocations,
  fetchLocationsFailed,
  fetchLocationsSuccess,
  fetchRegions,
  fetchRegionsSuccess,
  regionDistrictBulkUpdateFailed,
  regionDistrictBulkUpdateSuccess,
  updateContactFail,
  updateContactSuccess,
  fetchOfficeListSuccess,
  fetchLocationsForSearchSuccess,
} from './offices-actions';
import { getSearch } from './offices-selectors';
import { get, omit } from 'lodash';
import { api, showErrorNotification, showSuccessNotification, showWarningNotification } from '@med-fe/util';

export function* fetchLocationsStartWorker({ payload }: any): Generator {
  try {
    const url = '/locations/search';
    const data: any = yield call(api.post, url, payload);
    yield put(fetchLocationsSuccess(data));
  } catch (e) {
    yield put(fetchLocationsFailed(e));
    showErrorNotification(null, e);
  }
}

export function* createLocationStartWorker({ payload }): Generator {
  try {
    const url = '/locations';
    const search = yield select(getSearch);
    const nullableFields = Object.keys(payload)
      .filter((key) => payload[key] === null)
      .toString();
    const data = yield call(api.post, url, payload, { nullableFields: nullableFields });
    showSuccessNotification(`Location ${payload.id ? 'updated' : 'created'} successfully`);
    yield put(createLocationSuccess(payload));
    yield put(fetchLocations(search));
  } catch (e) {
    yield put(createLocationFailed(e));
    showErrorNotification(null, e);
  }
}

export function* deleteLocationStartWorker({ payload }: { payload: string }): Generator {
  try {
    const url = `/locations/${payload}`;
    const data = yield call(api.delete, url);
    yield put(deleteLocationSuccess(payload));
  } catch (e) {
    yield put(deleteLocationFailed(e));
    showErrorNotification(null, e);
  }
}

export function* fetchRegionsStartWorker({ payload }: any): Generator {
  try {
    let url = '/regions';

    if (payload && payload.length) {
      url = `${url} `;
    } else if (payload) {
      url = `${url}/${payload}`;
    }

    const data = yield call(api.get, url);
    yield put(fetchRegionsSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}

export function* fetchDistrictsStartWorker({ payload }: any): Generator {
  try {
    let url = '/districts';
    if (payload && payload.length > 0) {
      url = `${url}/search?regionIds=${payload.join(',')}`;
    } else if (typeof payload === 'number') {
      url = `${url}/${payload}`;
    } else if (typeof payload === 'array' && payload.length !== 0) {
      url = `${url}/${payload}/`;
    }
    const data = yield call(api.get, url);
    yield put(fetchDistrictsSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}

export function* searchDistrictsByAreaStartWorker({ payload }: any): Generator {
  try {
    let url = `/districts/search?regionIds=${payload.join(',')}`;
    const data = yield call(api.get, url);
    yield put(fetchDistrictsSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchOfficeListStartWorker({ payload }: any): Generator {
  try {
    let url = `/locations/numbers?`;
    const { officeNumber = '', page = 0 } = payload;
    if (payload.officeNumber) {
      url = url + `officeNumber=${officeNumber}&&`;
    }
    url = url + `page=${page}`;
    const data: any = yield call(api.get, url);
    yield put(fetchOfficeListSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}

export function* fetchLocationAssignmentCountStartWorker({ id }): Generator<any, any, any> {
  try {
    const url = `/contacts/assignments/${id}`;
    const data = yield call(api.get, url);
    yield put(fetchLocationAssignmentCountSuccess({ ...data, id }));
  } catch (e) {
    yield put(fetchLocationAssignmentCountFail(e));
    showErrorNotification(null, e);
  }
}

export function* updateContactStartWorker({ payload }): Generator {
  try {
    const search = yield select(getSearch);
    const url = `/contacts`;
    let createdContact: any;
    if (get(payload, 'info')) {
      createdContact = yield call(api.post, url, payload.info);
      showSuccessNotification(`Contact ${payload.info.id ? 'updated' : 'created'} successfully`);
    }
    if (get(payload, 'assign')) {
      if (createdContact) {
        payload.assign['contactId'] = createdContact.id;
      }
      yield call(api.post, `${url}/assign`, payload.assign);
      showSuccessNotification('Location updated successfully');
    }
    yield put(updateContactSuccess(payload));
    yield put(fetchLocations(search));
  } catch (e) {
    yield put(updateContactFail(e));
    showErrorNotification(e.response.data.reason);
  }
}

export function* fetchContactsStartWorker({ payload }): Generator {
  try {
    const url = `/contacts/search`;
    const payloadData = {
      name: payload.name,
      job: payload.job,
      page: 0,
      size: 10,
    };
    const data = yield call(api.post, url, payloadData);
    yield put(fetchContactsSuccess(data));
  } catch (e) {
    yield put(fetchContactsFail(e));
    showErrorNotification(null, e);
  }
}

export function* createRegionStartWorker({ payload }): Generator {
  try {
    const url = '/regions';
    const search = yield select(getSearch);
    const data = yield call(api.post, url, payload);
    showSuccessNotification(`Region ${payload.id ? 'updated' : 'created'} successfully`);
    yield put(createRegionSuccess(payload));
    yield put(fetchRegions());
    yield put(fetchLocations(search));
  } catch (e) {
    yield put(createRegionFailed(e));
    showErrorNotification(null, e);
  }
}

export function* createDistrictStartWorker({ payload }): Generator {
  try {
    const url = '/districts';
    const search = yield select(getSearch);
    const data = yield call(api.post, url, payload);
    showSuccessNotification(`District ${payload.id ? 'updated' : 'created'} successfully`);
    yield put(createDistrictSuccess(payload));
    yield put(fetchDistricts());
    yield put(fetchLocations(search));
  } catch (e) {
    yield put(createDistrictFailed(e));
    showErrorNotification(null, e);
  }
}

export function* regionDistrictBulkUpdateStartWorker({ payload: { payload, isAllSelected } }): Generator {
  try {
    let url = '/locations/bulk';
    const search: any = yield select(getSearch);
    const { page, size, ...locationSearchDto } = search;
    if (isAllSelected) {
      payload = {
        ...omit(payload, 'locationIds'),
        locationSearchDto,
      };

      url = `${url}All`;
    }

    const data = yield call(api.post, url, payload);

    showSuccessNotification('Details updated successfully');
    yield put(regionDistrictBulkUpdateSuccess(data));
    yield put(fetchLocations(search));
  } catch (e) {
    yield put(regionDistrictBulkUpdateFailed(e));
    showErrorNotification(null, e);
  }
}

export function* bulkUpdateStaffStartWorker({ payload: { payload, isAllSelected } }): Generator {
  try {
    let all = '';
    if (isAllSelected) {
      payload = omit(payload, 'locationIds');
      all = 'All';
    }
    let url = `/locations/bulk${all}/staff`;
    const data: any = yield call(api.post, url, payload);
    if (data) showWarningNotification(data);
  } catch (e) {
    showErrorNotification(null, e);
  }
}

export function* createBillingStartWorker({ payload }): Generator {
  try {
    const url = '/billing';
    // const data = yield call(api.post, url, payload.billing);
    // yield put(createLocation({...payload, billing: data}));
  } catch (e) {}
}

export function* unassignStuffStartWorker({ payload }): Generator {
  try {
    const url = `/contacts/remove`;
    const search = yield select(getSearch);
    const data = yield call(api.post, url, payload);
    yield put(fetchLocations(search));
    showSuccessNotification('Location updated successfully');
  } catch (e) {
    showErrorNotification(null, e);
  }
}

export function* fetchLocationsForSearchStartWorker({ payload }: any): Generator {
  try {
    const { search = '', page = 0 } = payload;
    let url = `/locations/numbers/search?page=${page}&search=${search}`;
    const data: any = yield call(api.get, url);
    yield put(fetchLocationsForSearchSuccess({ locations: data, page }));
  } catch (e) {
    showErrorNotification(null, e);
  }
}
